/** @jsx jsx */
import { useEffect } from 'react'
import { AspectRatio, Box, Text, Grid, jsx } from 'theme-ui'
import shallow from 'zustand/shallow'
import Stream from '@components/SurvivalStream'
import ReactPlayer from 'react-player'
import parse, { Element, domToReact } from 'html-react-parser'

import { useStore } from '@stores/3d'
import { ExternalLink } from '@components/Links'

const RichText = ({ html, margin = true }: { html?: string; margin?: boolean }) => {
  if (!html) return null
  return (
    <Box
      p={margin ? [3, 4] : undefined}
      sx={{
        '*:first-child': {
          marginTop: 0
        },
        'blockquote': {
          marginBlockStart: 0,
          marginBlockEnd: 0,
          marginInlineStart: 0,
          marginInlineEnd: 0,
        },
        'blockquote::before': {
          content: '"→ "',
        }
      }}
      >
      {parse(html, {
        replace: ((domNode: Element) => {
          if (domNode.type === 'tag' && domNode.name === 'a') {
            return <ExternalLink {...domNode.attribs}>
              {domToReact(domNode.children)}
            </ExternalLink>
          }
        }) as any
      })}
    </Box>
  )
}

const StreamBody = ({ test }: { test?: boolean }) => {
  const [
    live,
    redirect,
    showChat,
    clubScheduleText,
    radioScheduleText,
    archiveText,
    archiveEmbed,
  ] = useStore((state) => [
    state.live,
    state.redirect,
    state.showChat,
    state.clubScheduleText,
    state.radioScheduleText,
    state.archiveText,
    state.archiveEmbed,
  ], shallow)

  useEffect(() => {
    if (live === false && redirect && typeof window !== 'undefined' && window?.location?.href) {
      window.location.href = '/mixes'
    }
  }, [live, redirect])

  return (
    <Grid
      sx={{
        gridGap: 0,
        borderTop: '1px solid black',
        gridTemplateRows: [`auto`, `auto`, `85vh auto`],
        gridTemplateColumns: ['100%', '50% 50%', '1fr 1fr 1fr'],
        gridTemplateAreas: [
          showChat
            ? `'stream' 'chat' 'agenda' 'rrfm' 'archive'`
            : `'stream' 'rrfm' 'agenda' 'chat' 'archive'`,
          showChat
            ? `'stream stream' 'chat rrfm' 'agenda archive'`
            : `'stream stream' 'rrfm agenda' 'archive archive'`,
          `'stream stream stream' 'agenda rrfm archive'`,
        ],
      }}
    >
      <div
        sx={{
          display: 'grid',
          gridArea: 'stream',
          maxHeight: '85vh',
          bg: 'black',
          gap: 0,
          borderTop: '1px solid var(--main-color)',
          gridTemplateColumns: [
            '100%',
            '100%',
            showChat ? '1fr max-content' : '100%',
          ],
        }}
      >
        <AspectRatio
          ratio={16 / 9}
          sx={{
            backgroundColor: 'black',
          }}
        >
          <Stream />
        </AspectRatio>
        {showChat && (
          <div
            sx={{
              // bg: 'var(--main-color)',
              borderLeft: '1px solid var(--main-color)',
              display: ['none', 'none', 'flex'],
              width: '18em',
            }}
          >
            <iframe
              height="100%"
              width="100%"
              frameBorder="0"
              src="https://www.twitch.tv/embed/radioradiofm/chat?parent=radioradio.radio&darkpopout"
            />
          </div>
        )}
      </div>

      <Box
        sx={{
          gridArea: 'agenda',
          borderRight: ['none', '1px solid black'],
          borderBottom: ['1px solid black', 'none'],
        }}
      >
        <Box
          px={[3, 4]}
          sx={{
            py: 3,
            borderBottom: '1px solid black',
            borderTop: ['1px solid black', '1px solid black', 'none'],
          }}
        >
          Club Schedule
        </Box>
          <RichText html={clubScheduleText} />
        <Box
          px={[3, 4]}
          sx={{
            py: 3,
            borderBottom: '1px solid black',
            borderTop: '1px solid black',
          }}
        >
          Support RRFM
        </Box>
        <Box p={[3, 4]}>
          Become a RRFMLY member! Monthly subscribers get an
          exclusive newsletter and early access to tickets (€5 p/m),
          plus a monthly guestlist & thank you poster (€10 p/m),
          or two guestlists, a poster, T-shirt & bi-monthly gifts (€25 p/m)
          <script src="https://donorbox.org/widget.js" paypalExpress="true"></script>
          <iframe
            src="https://donorbox.org/embed/rrfm"
            name="donorbox"
            allowpaymentrequest="allowpaymentrequest"
            seamless="seamless"
            frameborder="0"
            scrolling="no"
            width="100%"
            sx={{marginTop: '1rem', width: '100%'}}
          ></iframe>
        </Box>
      </Box>

      <Box
        sx={{
          gridArea: 'rrfm',
          display: 'flex',
          flexDirection: 'column',
          borderRight: ['none', 'none', '1px solid black'],
        }}
      >
        <Box
          px={[3, 4]}
          sx={{
            py: 3,
            borderTop: ['1px solid black', 'none'],
            borderBottom: '1px solid black',
          }}
        >
          Radio Schedule
        </Box>
        <RichText html={radioScheduleText} />
      </Box>

      <Box
        sx={{
          gridArea: 'chat',
          display: ['flex', 'flex', 'none'],
          flexDirection: 'column',
          borderTop: ['1px solid black', 'none', 'none'],
          borderRight: ['none', '1px solid black', 'none'],
        }}
      >
        <Box px={[3, 4]} sx={{ py: 3, borderBottom: '1px solid black' }}>
          Chat
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          {showChat ? (
            <iframe
              height="425px"
              width="100%"
              frameBorder="0"
              src="https://www.twitch.tv/embed/radioradiofm/chat?parent=radioradio.radio"
            />
          ) : (
            <Text
              as="div"
              sx={{
                p: [3, 4],
              }}
            >
              Chat is offline.
            </Text>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          gridArea: 'archive',
          borderTop: ['none', '1px solid black', 'none'],
        }}
      >
        <Box px={[3, 4]} sx={{ py: 3, borderBottom: '1px solid black' }}>
          Archive
        </Box>
        <Box p={[3, 4]}>
          <iframe
            width="100%"
            height="450"
            scrolling="no"
            frameBorder="no"
            allow="autoplay"
            src={archiveEmbed}
            style={{ marginBottom: '20px' }}
          />
          <RichText html={archiveText} margin={false} />
        </Box>
      </Box>
    </Grid>
  )
}

export default StreamBody
