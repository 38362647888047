/** @jsx jsx */
import { jsx } from 'theme-ui'
import ReactPlayer from 'react-player'
import shallow from 'zustand/shallow'

import { useStore } from '@stores/3d'

const Stream = ({ test }: { hideFooter?: boolean; test?: boolean }) => {
  const { streamURL, rerunStreamURL, testStreamURL, live, rerun, color } =
    useStore(
      ({ live, rerun, streamURL, rerunStreamURL, testStreamURL, color }) => ({
        live,
        rerun,
        streamURL,
        rerunStreamURL,
        testStreamURL,
        color,
      }),
      shallow
    )
  const stream = test
    ? testStreamURL
    : rerun
    ? rerunStreamURL
    : live
    ? streamURL
    : undefined

  // return !stream || stream === '' ? (
  //   <div
  //     sx={{
  //       display: 'flex',
  //       alignItems: 'center',
  //       justifyContent: 'center',
  //       backgroundImage: `url(${require('../images/offline-poster.jpg')})`,
  //       backgroundSize: 'contain',
  //       backgroundRepeat: 'no-repeat',
  //       backgroundPosition: 'center',
  //       height: '100%',
  //     }}
  //   >
  //     <ReactPlayer
  //       className="player"
  //       url={require('../images/offline.mp4')}
  //       playing
  //       controls={false}
  //       muted={true}
  //       width="100%"
  //       height="100%"
  //       loop
  //       playsinline
  //     />
  //   </div>
  // ) : (
  return (
    <div
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <div
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: color,
          backgroundImage: `url(${require('../images/offline-poster.jpg')})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          height: '100%',
        }}
      >
        <iframe
            src="https://player.twitch.tv/?channel=radioradiofm&parent=radioradio.radio"
            frameBorder="0"
            allowFullScreen
            scrolling="no"
            height="100%"
            width="100%"
            ></iframe>
      </div>
    </div>
  )
}

export default Stream
