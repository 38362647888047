/** @jsx jsx */
import { RouteComponentProps } from '@reach/router'
import { jsx } from 'theme-ui'

import Layout from '@components/Layout'
import SEO from '@components/SEO'
import StreamBody from '@components/StreamBody'

const Home = ({
  hideFooter = false,
  test = false,
}: RouteComponentProps & { hideFooter?: boolean; test?: boolean }) => {
  return (
    <Layout hideFooter={hideFooter}>
      <SEO title="Radio Radio Live" />

      <StreamBody />
    </Layout>
  )
}

export default Home
